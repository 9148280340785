<div [@routerTransition] class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
        <!--begin::Header-->
        <div class="app-header" id="kt_app_header">
            <!--begin::Header container-->
            <div
                    class="app-container container-fluid d-flex align-items-stretch justify-content-between"
                    id="kt_app_header_container"
            >
                <!--begin::sidebar mobile toggle-->
                <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                    <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                    fill="none"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                ></path>
                                <path
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                        opacity="0.3"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                </div>
                <!--end::sidebar mobile toggle-->
                <!--begin::Mobile logo-->
                <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <default-logo
                            [customHrefClass]="'d-lg-none'"
                            [skin]="getMobileMenuSkin()"
                    ></default-logo>
                </div>
                <!--end::Mobile logo-->
                <!--begin::Header wrapper-->
                <div
                        class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
                        id="kt_app_header_wrapper"
                >
                    <!--begin::Menu wrapper-->
                    <div
                            *ngIf="appSession.theme.isTopMenuUsed"
                            class="app-header-menu app-header-mobile-drawer align-items-stretch"
                            data-kt-drawer="true"
                            data-kt-drawer-activate="{default: true, lg: false}"
                            data-kt-drawer-direction="end"
                            data-kt-drawer-name="app-header-menu"
                            data-kt-drawer-overlay="true"
                            data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                            data-kt-drawer-width="225px"
                            data-kt-swapper="true"
                            data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                    >
                        <top-bar-menu></top-bar-menu>
                    </div>
                    <div *ngIf="!appSession.theme.isTopMenuUsed">&nbsp;</div>
                    <!--end::Menu wrapper-->
                    <!--begin::Navbar-->
                    <div class="app-navbar flex-shrink-0">
                        <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo>
                        <subscription-notification-bar></subscription-notification-bar>
                        <date-top></date-top>
                        <quick-theme-selection></quick-theme-selection>
                        <language-switch-dropdown></language-switch-dropdown>
                        <header-notifications></header-notifications>
                        <chat-toggle-button></chat-toggle-button>
                        <toggle-dark-mode [isDarkModeActive]="isDarkModeActive()"></toggle-dark-mode>
                        <!-- <financialYear-menu></financialYear-menu> -->
                        <user-menu></user-menu>
                        
                    </div>
                    <!--end::Navbar-->
                </div>
                <!--end::Header wrapper-->
            </div>
            <!--end::Header container-->
        </div>
        <!--end::Header-->
        <!--begin::Wrapper-->
        <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
            <!--begin::sidebar-->
            <div
                    class="app-sidebar flex-column"
                    data-kt-drawer="true"
                    data-kt-drawer-activate="{default: true, lg: false}"
                    data-kt-drawer-direction="start"
                    data-kt-drawer-name="app-sidebar"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
                    data-kt-drawer-width="225px"
                    id="kt_app_sidebar"
            >
                <!--begin::Logo-->
                <div class="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                    <!--begin::Logo image-->
                    <default-brand></default-brand>
                    <!--end::Logo image-->
                    <!--begin::Sidebar toggle-->
                    <div
                            *ngIf="appSession.theme.baseSettings.menu.allowAsideMinimizing"
                            class="
                            app-sidebar-toggle
                            btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary
                            body-bg
                            h-30px
                            w-30px
                            position-absolute
                            top-50
                            start-100
                            translate-middle
                            rotate
                        "
                            data-kt-toggle="true"
                            data-kt-toggle-name="app-sidebar-minimize"
                            data-kt-toggle-state="active"
                            data-kt-toggle-target="body"
                            id="kt_app_sidebar_toggle"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
                        <span class="svg-icon svg-icon-2 rotate-180">
                            <svg
                                    fill="none"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                                        fill="currentColor"
                                        opacity="0.5"
                                ></path>
                                <path
                                        d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                                        fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Sidebar toggle-->
                </div>
                <!--end::Logo-->
                <!--begin::sidebar menu-->
                <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
                    <!--begin::Menu wrapper-->
                    <div
                            class="app-sidebar-wrapper hover-scroll-overlay-y my-5"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="true"
                            data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                            data-kt-scroll-height="auto"
                            data-kt-scroll-offset="5px"
                            data-kt-scroll-save-state="true"
                            data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                            id="kt_app_sidebar_menu_wrapper"
                    >
                        <side-bar-menu></side-bar-menu>
                    </div>
                    <!--end::Menu wrapper-->
                </div>
                <!--end::sidebar menu-->
            </div>
            <!--end::sidebar-->
            <!--begin::Main-->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                <!--begin::Content wrapper-->
                <div class="d-flex flex-column flex-column-fluid pb-5">
                    <router-outlet></router-outlet>
                </div>
                <!--end::Content wrapper-->
                <!--begin::Footer-->
                <div class="app-footer" id="kt_app_footer">
                    <!--begin::Footer container-->
                    <footer-bar></footer-bar>
                    <!--end::Footer container-->
                </div>
                <!--end::Footer-->
            </div>
            <!--end:::Main-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
