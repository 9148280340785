<div
    #verifyCodeModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="myLargeModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #verifyCodeModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'YourAuthenticatorCode' | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-5" id="inputDiv">
                        <label for="YourCode">{{ 'YourCode' | localize }} *</label>
                        <input
                            #codeInput="ngModel"
                            [(ngModel)]="verifyCodeInput.code"
                            autoFocus
                            class="form-control"
                            id="YourCode"
                            minlength="6"
                            name="Code"
                            required
                            type="text"
                        />
                        <validation-messages [formCtrl]="codeInput"></validation-messages>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                            [disabled]="!verifyCodeModalForm.form.valid || saving"
                            class="btn btn-primary"
                            type="submit"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Verify' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
