<div [ngClass]="{ 'subscription-bar-visible': subscriptionStatusBarVisible() }" class="d-flex flex-column flex-root">
    <default-layout></default-layout>
</div>

<kt-scroll-top></kt-scroll-top>

<linkedAccountsModal
    #linkedAccountsModal
    (modalClose)="getRecentlyLinkedUsers()"
    *ngIf="!installationMode"
></linkedAccountsModal>
<userDelegationsModal #userDelegationsModal></userDelegationsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<theme-selection-panel
    #themeSelectionPanelComponent
    *ngIf="!installationMode && isQuickThemeSelectEnabled"
></theme-selection-panel>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>
