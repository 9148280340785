import {IThemeAssetContributor} from '@app/shared/layout/themes/ThemeAssetContributor';
import {DefaultThemeAssetContributor} from '@app/shared/layout/themes/default/DefaultThemeAssetContributor';

export class ThemeAssetContributorFactory {
    static getCurrent(): IThemeAssetContributor {


        return new DefaultThemeAssetContributor();
    }
}
