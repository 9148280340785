import {Component, Injector, Input, OnInit} from '@angular/core';
import {ThemesLayoutBaseComponent} from '../themes/themes-layout-base.component';
import {AbpSessionService} from 'abp-ng2-module';
import {DateTimeService} from '@app/shared/common/timing/date-time.service';
import {BranchListDto, DateSelectDto, DateSelectsServiceProxy, FinancialYearSelectsServiceProxy} from '@shared/service-proxies/service-proxies';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
    selector: 'date-top',
    templateUrl: './date-top-component.html',

})
export class DateTopComponent extends ThemesLayoutBaseComponent implements OnInit {
    @Input() customStyle = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative';
    @Input() iconStyle = 'fa-duotone fa-messages fs-4';

    unreadChatMessageCount = 0;
    chatConnected = false;
    isHost = false;
    today: string;
    form: FormGroup;
  
    voucherNo: any;
    allBranch: BranchListDto[];
    selectedBranch: string
    selectedDate: DateSelectDto;
    financialYear: string='';

    public constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        private _proxy: DateSelectsServiceProxy,
        private fb: FormBuilder,
        private financialYearSelectProxy: FinancialYearSelectsServiceProxy,
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
        this.registerToEvents();
        this.createForm();
    }

    registerToEvents() {
        if(this._abpSessionService.tenantId)
        {
            this.getSelectedDate();
            this.getAllbranch();
            this.getfinancialYear();
        }
      
       
    }

    getSelectedDate() {
        this._proxy.getSelectDate().subscribe((result) => {
            this.selectedDate = result;
            this.createForm(result);
        });
    }

    getfinancialYear() {
        this.financialYearSelectProxy.getSelectFinancialYear().subscribe((result) => {
            this.financialYear = result;
        
        });
    }

    createForm(item: any = {}) {
       
        this.form = this.fb.group({
            selectDate: [item.selectDate ? item.selectDate : this.today, Validators.required],
            branchId: [item.branchId ? item.branchId : this.emptyguId, Validators.required],
        })
    }

    save() {
        console.log(this.form.getRawValue());
        this.notify.error(this.form.getRawValue());
    }

    getAllbranch() {
        this._proxy.getAllBranchDropDown().subscribe((result) => {
            this.allBranch = result;
        });
    }
}

