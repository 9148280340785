<!-- begin::Quick Sidebar -->
<div
        class="bg-body drawer drawer-end"
        data-kt-drawer="true"
        data-kt-drawer-activate="true"
        data-kt-drawer-close="#kt_explore_close"
        data-kt-drawer-direction="end"
        data-kt-drawer-name="explore"
        data-kt-drawer-overlay="true"
        data-kt-drawer-toggle="#kt_explore_toggle"
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        id="kt_explore"
>
    <div class="card shadow-none rounded-0 w-100">
        <div class="card-header" id="kt_explore_header">
            <h3 class="card-title fw-bolder text-gray-700">
                {{ 'SelectATheme' | localize }}
            </h3>
            <div class="card-toolbar">
                <button class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_explore_close" type="button">
                    <span class="svg-icon svg-icon-2">
                        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <rect
                                    fill="black"
                                    height="2"
                                    opacity="0.5"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    width="16"
                                    x="6"
                                    y="17.3137"
                            ></rect>
                            <rect
                                    fill="black"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    width="16"
                                    x="7.41422"
                                    y="6"
                            ></rect>
                        </svg>
                    </span>
                    <!---->
                </button>
            </div>
        </div>
        <div class="card-body" id="kt_explore_body">
            <div
                    class="scroll-y me-n5 pe-5"
                    data-kt-scroll="true"
                    data-kt-scroll-dependencies="#kt_explore_header"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-offset="5px"
                    data-kt-scroll-wrappers="#kt_explore_body"
                    id="kt_explore_scroll"
            >
                <div class="row g-5">
                    <div
                            (click)="changeTheme(theme)"
                            *ngFor="
                            let theme of [
                                'default',
                                'theme2',
                                'theme3',
                                'theme4',
                                'theme5',
                                'theme6',
                                'theme7',
                                'theme8',
                                'theme9',
                                'theme10',
                                'theme11',
                                'theme12',
                                'theme13'
                            ]
                        "
                            class="col-6"
                    >
                        <div
                                [ngClass]="{ 'border-success': theme == currentThemeName }"
                                class="overlay overflow-hidden position-relative border border-4 rounded"
                        >
                            <div class="overlay-wrapper">
                                <img
                                        [src]="'/assets/common/images/metronic-themes/' + theme + '.png'"
                                        alt="demo"
                                        class="w-100"
                                />
                            </div>
                            <div class="overlay-layer bg-dark bg-opacity-10">
                                <button class="btn btn-sm btn-success shadow" href="">
                                    {{ getLocalizedThemeName(theme) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end::Quick Sidebar -->
