<div class="p-4">
    <span *ngIf="selectedDate" class="badge badge-light-success">{{selectedDate.selectedMiti}}</span>
    <span class="p-2"></span>
    <span class="badge badge-light-danger">{{financialYear}}</span>
    <span class="p-2"></span>
    <span *ngIf="selectedDate" class="badge badge-light-primary">{{selectedDate.branchName}}</span>
</div>


<!-- <form [formGroup]="form" autocomplete="off">
   <ne-datepicker language="en" 

            [isfinancialYear]="false"
            [isengDate]="false"

    ></ne-datepicker>

    <select
            [(ngModel)]="selectedBranch"
            aria-label="Default select example"
            class="form-select"
    >
        <option *ngFor="let branch of allBranch"
                [value]="branch.id">
            {{ branch.displayName }}
        </option>
    </select>
 -->
