<div
    #viewRecoveryCodesModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="myLargeModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span>{{ 'ViewRecoveryCodes' | localize }}</span>
                </h5>
                <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button">close</button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="card">
                        <div class="card-body pt-0">
                            <recoveryCodesComponent #recoveryCodesComponent></recoveryCodesComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<verifyCodeModal #verifyCodeModal (modalSave)="showRecoveryCodes(verifyCodeModal.verifyCodeInput)"></verifyCodeModal>