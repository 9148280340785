<div
    #userDelegationModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="createOrEditModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <form #userDelegationForm="ngForm" (ngSubmit)="save()" *ngIf="active" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'DelegateNewUser' | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-5">
                        <label for="UsernameOrEmailAddress">{{ 'Username' | localize }}</label>
                        <div (click)="showCommonLookupModal()" class="input-group">
                            <input
                                    [value]="selectedUsername"
                                    class="form-control"
                                    disabled="disabled"
                                    name="UsernameOrEmailAddress"
                                    readonly="readonly"
                                    type="text"
                            />
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button">{{ 'Search' | localize }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label for="StartTime">{{ 'StartTime' | localize }}</label>
                        <input
                                [(date)]="userDelegation.startTime"
                                [(ngModel)]="userDelegation.startTime"
                                [bsConfig]="{ adaptivePosition: true }"
                                [maxDate]="userDelegation.endTime"
                                autocomplete="new-password"
                                bsDatepicker
                                class="form-control"
                                datePickerLuxonModifier
                                id="StartTime"
                                name="StartTime"
                                required
                                type="text"
                        />
                    </div>
                    <div class="mb-5">
                        <label for="EndTime">{{ 'EndTime' | localize }}</label>
                        <input
                                [(date)]="userDelegation.endTime"
                                [(ngModel)]="userDelegation.endTime"
                                [bsConfig]="{ adaptivePosition: true }"
                                [minDate]="userDelegation.startTime"
                                autocomplete="new-password"
                                bsDatepicker
                                class="form-control"
                                datePickerLuxonModifier
                                id="EndTime"
                                name="EndTime"
                                required
                                type="text"
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                            (click)="close()"
                            [disabled]="saving"
                            class="btn btn-light-primary fw-bold"
                            type="button"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                            [busyText]="l('SavingWithThreeDot')"
                            [buttonBusy]="saving"
                            [disabled]="!userDelegation.targetUserId || !userDelegationForm.form.valid"
                            class="btn btn-primary fw-bold"
                            type="submit"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<commonLookupModal #userLookupModal (itemSelected)="userSelected($event)"></commonLookupModal>
