import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'fa-duotone fa-house',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'fa-duotone fa-cube', '/app/admin/editions'),
            new AppMenuItem(
                'Accounting',
                'Pages.Accounting',
                'fa-duotone fa-books',
                '',
                [],
                [
                    new AppMenuItem(
                        'Account Groups',
                        'Pages.AccountGroups',
                        'fa-duotone fa-user-group',
                        '/app/main/accounting/accountGroups'
                    ),
                    new AppMenuItem(
                        'Account Ledgers',
                        'Pages.AccountLedgers',
                        'fa-duotone fa-memo',
                        '/app/main/accounting/accountLedgers'
                    ),

                    new AppMenuItem('Member', 'Pages.Member', 'fa-duotone fa-users-rays', '/app/main/generalSetting/member'),
                    new AppMenuItem(
                        'Financial Years',
                        'Pages.FinancialYears',
                        'fa-duotone fa-calendar',
                        '/app/main/accounting/financialYears'
                    ),
                    new AppMenuItem('Taxes', 'Pages.Taxes', 'fa-duotone fa-money-check-dollar', '/app/main/generalSetting/taxes'),
                    new AppMenuItem(
                        'Voucher Types',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-receipt',
                        '/app/main/generalSetting/voucherTypes'
                    ),
                    new AppMenuItem(
                        'Merge Ledger',
                        'Pages.MergeLedger',
                        'fa-duotone fa-layer-plus',
                        '/app/main/accounting/mergeLedger'
                    ),
                ]
            ),

            new AppMenuItem(
                'Inventory',
                'Pages.Inventory',
                'fa-duotone fa-pancakes',
                '',
                [],
                [
                    new AppMenuItem('Basics', 'Pages.Inventory', 'fa fa-window-maximize', '', [], [
                        new AppMenuItem('Units', 'Pages.Units', 'fa-duotone fa-weight-scale', '/app/main/inventory/units'),
                        new AppMenuItem('ModelNos', 'Pages.ModelNos', 'fa-duotone fa-input-numeric', '/app/main/inventory/modelNos'),
                        new AppMenuItem('Brands', 'Pages.Brands', 'fa-duotone fa-copyright', '/app/main/inventory/brands'),
                        new AppMenuItem('Sizes', 'Pages.Sizes', 'fa-duotone fa-arrow-up-small-big', '/app/main/inventory/sizes'),
                        new AppMenuItem('Godowns', 'Pages.Godowns', 'fa-duotone fa-store', '/app/main/inventory/godowns'),
                        new AppMenuItem('Racks', 'Pages.Racks', 'fa-duotone fa-layer-group', '/app/main/inventory/racks'),
                    ]),
                    new AppMenuItem(
                        'ProductGroups',
                        'Pages.ProductGroups',
                        'fa-duotone fa-ball-pile',
                        '/app/main/inventory/productGroups'
                    ),
                    new AppMenuItem('Products', 'Pages.Products', 'fa-duotone fa-boxes-stacked', '/app/main/inventory/products'),
                    new AppMenuItem(
                        'Opening Stocks',
                        'Pages.Products',
                        'fa-duotone fa-chart-line-up',
                        '/app/main/inventory/openingStock'
                    ),
                    new AppMenuItem('Extras', 'Pages.Inventory', 'fa fa-plus-square', '', [], [
                        new AppMenuItem(
                            'Pricing Level',
                            'Pages.PricingLevels',
                            'fa-duotone fa-indian-rupee-sign',
                            '/app/main/inventory/pricinglevel'
                        ),
                        new AppMenuItem('PricingList', 'Pages.PriceLists', 'fa-duotone fa-list', '/app/main/inventory/pricingList'),
                        new AppMenuItem(
                            'Barcode Print',
                            'Pages.Barcode',
                            'fa-duotone fa-barcode-scan',
                            '/app/main/inventory/barcode-change'
                        ),
                        new AppMenuItem(
                            'Consumer Type',
                            'Pages.ConsumerTypes',
                            'fa-duotone fa-users-between-lines',
                            '/app/main/inventory/consumer-type'
                        ),

                        new AppMenuItem(
                            'Sales Additional',
                            'Pages.SalesAdditional',
                            'fa-duotone fa-users-between-lines',
                            '/app/main/inventory/salesadditional'
                        ),


                    ]),
                ]
            ),

            new AppMenuItem(
                'Transaction',
                'Pages.Transaction',
                'fa-duotone fa-gift-card',
                '',
                [],
                [
                    new AppMenuItem(
                        'Contra Masters',
                        'Pages.ContraMasters',
                        'fa-duotone fa-bank',
                        '/app/main/transaction/contraMasters'
                    ),
                    new AppMenuItem(
                        'Payment Masters',
                        'Pages.PaymentMasters',
                        'fa-duotone fa-sack-dollar',
                        '/app/main/transaction/paymentMasters'
                    ),
                    new AppMenuItem(
                        'Receipt Masters',
                        'Pages.ReceiptMasters',
                        'fa-duotone fa-receipt',
                        '/app/main/transaction/receiptMaster'
                    ),
                    new AppMenuItem(
                        'Journal Masters',
                        'Pages.JournalMasters',
                        'fa-duotone fa-book',
                        '/app/main/transaction/journalMasters'
                    ),
                    new AppMenuItem(
                        'Stock Journal',
                        'Pages.StockJournalMasters',
                        'fa-duotone fa-file-chart-column',
                        '/app/main/transaction/stockJournal'
                    ),
                    new AppMenuItem(
                        'BankReconciliation',
                        'Pages.BankReconciliations',
                        'fa-duotone fa-piggy-bank',
                        '/app/main/transaction/BankReconciliationnew'
                    ),
                    new AppMenuItem(
                        'Bill Allocation',
                        'Pages.BillAllocation',
                        'pi pi-list',
                        '/app/main/transaction/billAllocation'
                    ),
                    new AppMenuItem('Loan Master', 'Pages.LoanMaster', 'pi pi-list', '/app/main/transaction/loanMaster'),
                    new AppMenuItem(
                        'PDC Payable',
                        'Pages.PdcPayables',
                        'fa-duotone fa-hand-holding-dollar',
                        '/app/main/transaction/PdcPayable'
                    ),
                    new AppMenuItem(
                        'PDC Receivable',
                        'Pages.PdcReceivables',
                        'fa-duotone fa-hands-holding-dollar',
                        '/app/main/transaction/PdcReceivable'
                    ),
                    new AppMenuItem(
                        'PDC Clearance',
                        'Pages.PdcClearances',
                        'fa-duotone fa-file-check',
                        '/app/main/transaction/PdcClearance'
                    ),
                    new AppMenuItem(
                        'Physical Stock',
                        'Pages.PhysicalStockMasters',
                        'fa-duotone fa-boxes-stacked',
                        '/app/main/transaction/physical-stock'
                    ),
                    new AppMenuItem(
                        'Stock Request',
                        'Pages.StockRequestMasters',
                        'fa-duotone fa-money-check-pen',
                        '/app/main/transaction/stockRequest'
                    ),
                    new AppMenuItem(
                        'Stock Transfer',
                        'Pages.StockTransferReport',
                        'fa-duotone fa-truck',
                        '/app/main/transaction/stockTransfer'
                    ),
                    new AppMenuItem(
                        'Stock Receipt',
                        'Pages.StockReceipt',
                        'fa-duotone fa-receipt',
                        '/app/main/transaction/stockReceipt'
                    ),
                    new AppMenuItem(
                        'Loan',
                        'Pages.Loan',
                        'fa-duotone fa-circle-dollar',
                        '',
                        [],
                        [
                            new AppMenuItem('EMI', 'Pages.EMI', 'fa-duotone fa-calendar-days', '/app/loan/emiMaster'),
                            new AppMenuItem('STL', 'Pages.STL', 'fa-duotone fa-hand-holding-dollar', '/app/loan/stlMaster'),
                            new AppMenuItem('BankOD', 'Pages.ODA', 'fa-duotone fa-building-columns', '/app/loan/odMaster'),
                            new AppMenuItem('LoC', 'Pages.LC', 'fa-duotone fa-envelope-open-dollar', '/app/loan/lcMaster'),
                        ]
                    ),
                ]
            ),

            new AppMenuItem(
                'Purchase',
                'Pages.Purchase',
                'fa-duotone fa-cart-shopping-fast',
                '',
                [],
                [
                    new AppMenuItem(
                        'PurchaseOrder',
                        'Pages.PurchaseOrderMasters',
                        'fa-duotone fa-cart-plus',
                        '/app/main/purchase/purchaseOrderMasters'
                    ),
                    new AppMenuItem(
                        'MaterialReceipt',
                        'Pages.MaterialReceiptMasters',
                        'fa-duotone fa-receipt',
                        '/app/main/purchase/materialReceiptMasters'
                    ),
                    new AppMenuItem(
                        'RejectionOut',
                        'Pages.RejectionOutMasters',
                        'fa-duotone fa-circle-xmark',
                        '/app/main/purchase/rejectionOut'
                    ),
                    new AppMenuItem(
                        'PurchaseInvoice',
                        'Pages.PurchaseMasters',
                        'fa-duotone fa-file-invoice-dollar',
                        '/app/main/purchase/purchaseMasters'
                    ),
                    new AppMenuItem(
                        'PurchaseReturns',
                        'Pages.PurchaseReturns',
                        'fa-duotone fa-rotate-left',
                        '/app/main/purchase/purchaseReturns'
                    ),
                ]
            ),

            new AppMenuItem(
                'Sales',
                'Pages.Sales',
                'fa-duotone fa-cart-circle-check',
                '',
                [],
                [
                    new AppMenuItem(
                        'POS',
                        'Pages.SalesMasters.PosCreate',
                        'fa-duotone fa-cart-plus',
                        '/app/main/sales/sales-pos'
                    ),
                    new AppMenuItem(
                        'SalesQuotation',
                        'Pages.SalesQuotationMasters',
                        'fa-duotone fa-dolly',
                        '/app/main/sales/salesQuotationMasters'
                    ),
                    new AppMenuItem(
                        'SalesOrder',
                        'Pages.SalesOrderMasters',
                        'fa-duotone fa-bags-shopping',
                        '/app/main/sales/salesOrderMaster'
                    ),
                    new AppMenuItem(
                        'DeliveryNote',
                        'Pages.DeliveryNoteMasters',
                        'fa-duotone fa-notebook',
                        '/app/main/sales/deliveryNoteMaster'
                    ),

                    // new AppMenuItem(
                    //     'Delivery Dashboard',
                    //     'Pages.DeliveryNoteMasters',
                    //     'fa-duotone fa-notebook',
                    //     '/app/main/sales/deliveryNoteDashboard'
                    // ),
                    new AppMenuItem(
                        'RejectionIn',
                        'Pages.RejectionInMasters',
                        'fa-duotone fa-circle-xmark',
                        '/app/main/sales/rejectionIn'
                    ),
                    new AppMenuItem(
                        'SalesInvoice',
                        'Pages.SalesMasters',
                        'fa-duotone fa-file-invoice-dollar',
                        '/app/main/sales/salesInvoiceMasters'
                    ),
                    new AppMenuItem(
                        'SalesReturn',
                        'Pages.SalesReturnMasters',
                        'fa-duotone fa-cart-circle-xmark',
                        '/app/main/sales/salesReturnMasters'
                    ),


                ]
            ),

            new AppMenuItem(
                'FinancialStatement',
                'Pages.FinancialStatement',
                'fa-duotone fa-chart-mixed',
                '',
                [],
                [
                    new AppMenuItem(
                        'Trial Balance',
                        'Pages.TrialBalanceReport',
                        'fa-duotone fa-receipt',
                        '/app/main/reports/trial-balance'
                    ),

                    new AppMenuItem(
                        'ProfitandLoss',
                        'Pages.ProfitAndLossReport',
                        'fa-duotone  fa-file-chart-pie',
                        '/app/main/reports/profit-loss'
                    ),
                    new AppMenuItem(
                        'BalanceSheet',
                        'Pages.PagesBalanceSheetReport',
                        'fa-duotone fa-memo-pad',
                        '/app/main/reports/balance-sheet'
                    ),
                    new AppMenuItem(
                        'CashFlow',
                        'Pages.PagesCashFlowReport',
                        'fa-duotone fa-money-bill-transfer',
                        '/app/main/reports/cash-flow'
                    ),
                ]
            ),
            new AppMenuItem(
                'Payroll',
                'Pages.Payroll',
                'fa-duotone fa-hand-holding-dollar',
                '',
                [],
                [
                    new AppMenuItem(
                        'Departments',
                        'Pages.Departments',
                        'fa-duotone fa-building-columns',
                        '/app/main/controlPanel/departments'
                    ),
                    new AppMenuItem(
                        'Designations',
                        'Pages.Designations',
                        'fa-duotone fa-user-tie',
                        '/app/main/controlPanel/designations'
                    ),
                    new AppMenuItem('PayHeads', 'Pages.PayHeads', 'fa-duotone fa-user-check', '/app/main/payroll/payHeads'),
                    new AppMenuItem('WorkUnit', 'Pages.WorkUnits', 'fa-duotone fa-screen-users', '/app/main/payroll/workUnit'),
                    new AppMenuItem(
                        'EmployeeTaxSetting',
                        'Pages.EmployeeTaxSettings',
                        'fa-duotone fa-credit-card',
                        '/app/main/payroll/employeeTaxSetting'
                    ),
                    new AppMenuItem('Employees', 'Pages.Employees', 'fa-duotone fa-users', '/app/main/controlPanel/employees'),
                    new AppMenuItem(
                        'EmployeesCalender',
                        'Pages.Employees',
                        'fa-duotone fa-calendar-days',
                        '/app/main/payroll/employeeCalendar'
                    ),
                    new AppMenuItem(
                        'EmployeeOffice',
                        'Pages.AttendanceMasters',
                        'fa-duotone fa-buildings',
                        '/app/main/payroll/EmployeeOffice'
                    ),
                    new AppMenuItem(
                        'StaffAttendance',
                        'Pages.AttendanceMasters',
                        'fa-duotone fa-rainbow',
                        '/app/main/payroll/staffAttendance'
                    ),
                    new AppMenuItem(
                        'BonusDeduction',
                        'Pages.EmployeeBonusDeductions',
                        'fa-duotone fa-circle-minus',
                        '/app/main/payroll/BonusDeduction'
                    ),
                    new AppMenuItem(
                        'AdvancePayment',
                        'Pages.EmployeeLoanAndAdvances',
                        'fa-duotone fa-dollar-sign',
                        '/app/main/payroll/advancePayment'
                    ),
                    new AppMenuItem(
                        'LopDeduction',
                        'Pages.EmployeeLopDeductionMasters',
                        'fa-duotone fa-user-minus',
                        '/app/main/payroll/lopDeduction'
                    ),
                    new AppMenuItem(
                        'EmployeeOvertime',
                        'Pages.AttendanceMasters',
                        'fa-duotone fa-user-clock',
                        '/app/main/payroll/EmployeeOvertime'
                    ),
                    new AppMenuItem(
                        'MonthlySalary',
                        'Pages.EmployeeSalaryVoucherMasters',
                        'fa-duotone fa-money-bills',
                        '/app/main/payroll/monthlySalary'
                    ),
                    new AppMenuItem('PaySlip', 'Pages.PaySlips', 'fa-duotone fa-memo', '/app/main/payroll/paySlip'),
                ]
            ),

            new AppMenuItem(
                'CRM',
                'Pages.Crm',
                'fa-duotone fa-handshake',
                '',
                [],
                [
                    new AppMenuItem('Account', 'Pages.LoanReceives', 'fa-duotone fa-phone', '/app/main/crm/account'),
                    new AppMenuItem('Calls', 'Pages.CrmCall', 'fa-duotone fa-phone', '/app/main/crm/calls'),
                    new AppMenuItem('Contact', 'Pages.CrmContacts', 'fa-duotone fa-address-book', '/app/main/crm/contact'),
                    new AppMenuItem('Deals', 'Pages.CrmDeals', 'fa-duotone fa-handshake', '/app/main/crm/deal'),
                    new AppMenuItem('Lead', 'Pages.CrmLeads', 'fa-duotone fa-arrow-up-right', '/app/main/crm/lead'),
                    new AppMenuItem('Meetings', 'Pages.CrmMeeting', 'fa-duotone fa-people', '/app/main/crm/meetings'),
                    new AppMenuItem('Task', 'Pages.CrmTask', 'fa-duotone fa-list-check', '/app/main/crm/task'),
                    new AppMenuItem('Customer', 'Pages.Customers', 'fa-duotone fa-cart-circle-arrow-down', '/app/main/crm/customer'),
                    new AppMenuItem('Technician', 'Pages.CrmTask', 'fa-duotone fa-receipt', '/app/main/crm/technician'),
                    new AppMenuItem(
                        'Tickets',
                        'Pages.Customers',
                        'fa-duotone fa-cart-circle-arrow-down',
                        '/app/main/crm/crmtickets'
                    ),
                    new AppMenuItem(
                        'TicketsReport',
                        'Pages.Customers',
                        'fa-duotone fa-cart-circle-arrow-down',
                        '/app/main/crm/cticket'
                    ),
                    new AppMenuItem(
                        'CrmProducts',
                        'Pages.Customers',
                        'fa-duotone fa-cart-circle-arrow-down',
                        '/app/main/crm/crmproducts'
                    ),
                ]
            ),

            new AppMenuItem(
                'Services',
                'Pages.Service',
                'fa-duotone fa-screwdriver-wrench',
                '',
                [],
                [
                    new AppMenuItem(
                        'Job Order',
                        'Pages.ServiceOrders',
                        'fa-duotone fa-cart-circle-arrow-down',
                        '/app/main/service/ServiceOrder'
                    ),
                    new AppMenuItem(
                        'Job Receipt',
                        'Pages.ServiceReceipt',
                        'fa-duotone fa-receipt',
                        '/app/main/service/ServiceReceipt'
                    ),
                    new AppMenuItem('Job Assign', 'Pages.ServiceReceipt', 'fa-solid fa-hand-holding-hand', '/app/main/service/jobAssignNew'),
                    new AppMenuItem(
                        'Warranty Transfer',
                        'Pages.WarrantyTransfers',
                        'fa-duotone fa-share',
                        '/app/main/service/ServiceTransfer'
                    ),
                    new AppMenuItem(
                        'Warranty Receipt',
                        'Pages.WarrantyReceipts',
                        'fa-duotone fa-inbox-in',
                        '/app/main/service/TransferReceive'
                    ),
                    new AppMenuItem(
                        'Job Completed',
                        'Pages.ServiceDeliveries',
                        'fa-duotone fa-truck',
                        '/app/main/service/ServiceDelivery'
                    ),
                    new AppMenuItem('Tickets', 'Pages.TicketMasters', 'fa-duotone fa-ticket', '/app/main/service/Tickets'),
                    new AppMenuItem(
                        'Sales For Ticket',
                        'Pages.SalesForTicketMasters',
                        'fa-duotone fa-ticket',
                        '/app/main/service/salesforticket'
                    ),
                ]
            ),

            new AppMenuItem(
                'Reports',
                'Pages.Reporting',
                'fa-duotone fa-chart-simple',
                '',
                [],
                [
                    new AppMenuItem(
                        'Accounting',
                        'Pages.AccountingReport',
                        'fa-duotone fa-books',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'AccountGroup',
                                'Pages.AccountGroupReport',
                                'fa-duotone fa-layer-group',
                                '/app/main/reports/account-group'
                            ),
                            new AppMenuItem(
                                'AccountLedger',
                                'Pages.AccountLedgerReport',
                                'fa-duotone fa-memo-pad',
                                '/app/main/reports/account-ledger-report'
                            ),
                            new AppMenuItem('Ageing', 'Pages.AgeingReport', 'fa-duotone fa-list', '/app/main/reports/ageingreport'),
                            new AppMenuItem(
                                'CashBankBook',
                                'Pages.CashBankBookReport',
                                'fa-duotone fa-book',
                                '/app/main/reports/cash-bank'
                            ),
                            new AppMenuItem(
                                'Cheque',
                                'Pages.ChequeReport',
                                'fa-duotone fa-money-check-pen',
                                '/app/main/reports/cheque'
                            ),
                            new AppMenuItem(
                                'DailyCollection',
                                'Pages.DailyCollectionReport',
                                'fa-duotone fa-calendar',
                                '/app/main/reports/daily-collection'
                            ),
                            new AppMenuItem(
                                'DayBook',
                                'Pages.DayBookReport',
                                'fa-duotone fa-book-open-cover',
                                '/app/main/reports/day-book'
                            ),
                            new AppMenuItem(
                                'Ledger Wise Monthly',
                                'Pages.PurchaseReturnReport',
                                'fa-duotone fa-calendar-days',
                                '/app/main/reports/ledgerWiseMonthlyPurchase'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Inventory',
                        'Pages.InventoryReport',
                        'fa-duotone fa-cart-flatbed-boxes',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'AvailableIMEI',
                                'Pages.AgeingReport',
                                'fa-duotone fa-circle-check',
                                '/app/main/reports/available-imei'
                            ),
                            new AppMenuItem(
                                'IMEICheck',
                                'Pages.ImeiCheckServiceReport',
                                'fa-duotone fa-clipboard-check',
                                '/app/main/reports/imei-check'
                            ),
                            new AppMenuItem(
                                'IMEITrack',
                                'Pages.ImeiTrackerReport',
                                'fa-duotone fa-chart-line',
                                '/app/main/reports/imei-track'
                            ),
                            new AppMenuItem(
                                'ProductProfit',
                                'Pages.ProductProfitReport',
                                'fa-duotone fa-chart-mixed',
                                '/app/main/reports/product-profit'
                            ),

                            new AppMenuItem(
                                'Price Level Included',
                                'Pages.ProductProfitReport',
                                'fa-duotone fa-chart-mixed',
                                '/app/main/reports/priceLevelIncluded'
                            ),
                            // new AppMenuItem(
                            //     'Product Wise Profit',
                            //     'Pages.ProductProfitReport',
                            //     'fa-duotone fa-chart-mixed',
                            //     '/app/main/reports/product-wise-profit/:id'
                            // ),
                            new AppMenuItem(
                                'ProductStatistics',
                                'Pages.ProductStatisticsReport',
                                'fa-duotone fa-chart-column',
                                '/app/main/reports/new-product-statistics'
                            ),
                            // new AppMenuItem('Stock', 'Pages.StockReport', 'fa-duotone fa-chart-line-up', '/app/main/reports/stock'),

                            new AppMenuItem(
                                'Stock Report',
                                'Pages.StockReport',
                                'fa-duotone fa-layer-group',
                                '/app/main/reports/stockUnfinished'
                            ),
                            new AppMenuItem(
                                'Movement Analysis',
                                'Pages.MovementAnalysisReport',
                                'fa-duotone fa-layer-plus',
                                '/app/main/reports/MovementAnalysis'
                            ),
                            new AppMenuItem(
                                'Movement Analysis Detail',
                                'Pages.MovementAnalysisReport',
                                'fa-duotone fa-layer-plus',
                                '/app/main/reports/MovementAnalysisDetail/:ledgerId/:productId'
                            ),
                            // new AppMenuItem("SalesSummary", "Pages.SalesSummaryReport", "fa-duotone fa-journal-text", "/app/main/reports/sales-summary"),
                            new AppMenuItem(
                                'SoftwareReport',
                                'Pages.SoftwareReport',
                                'fa-duotone fa-receipt',
                                '/app/main/reports/software-report'
                            ),
                            //new AppMenuItem("StockExpiry ", "Pages.ShortExpiryReport", "fa-duotone fa-cloud-slash", "/app/main/reports/short-expiry"),
                        ]
                    ),
                    new AppMenuItem(
                        'Transaction',
                        'Pages.TransactionReporting',
                        'fa-duotone fa-money-bill-transfer',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'OutStanding',
                                'Pages.OutstandingReport  ',
                                'fa-duotone fa-handbag',
                                '/app/main/reports/outstanding'
                            ),
                            new AppMenuItem(
                                'PaymentVoucher',
                                'Pages.PaymentVoucherReport',
                                'fa-duotone fa-notes-medical',
                                '/app/main/reports/payment-voucher'
                            ),
                            new AppMenuItem(
                                'ReceiptVoucher',
                                'Pages.ReceiptVoucherReport',
                                'fa-duotone fa-receipt',
                                '/app/main/reports/receipt-voucher'
                            ),
                            new AppMenuItem(
                                'ContraMasters',
                                'Pages.ContraMasterReport',
                                'fa-duotone fa-bank',
                                '/app/main/reports/contra-master'
                            ),
                            new AppMenuItem(
                                'JournalMasters',
                                'Pages.JournalMasterReport',
                                'fa-duotone fa-newspaper',
                                '/app/main/reports/journalMaster'
                            ),
                            new AppMenuItem(
                                'StockJournal',
                                'Pages.StockJournalReport',
                                'fa-duotone fa-chart-candlestick',
                                '/app/main/reports/stockJournal'
                            ),
                            new AppMenuItem(
                                'PDC Payable',
                                'Pages.PdcPayableReport',
                                'fa-duotone fa-circle-up',
                                '/app/main/reports/pdc-payable'
                            ),
                            new AppMenuItem(
                                'PDC Receivable',
                                'Pages.PdcReceivableReport',
                                'fa-duotone fa-circle-down',
                                '/app/main/reports/PdcReceivable'
                            ),
                            new AppMenuItem(
                                'PDC Clearance',
                                'Pages.PdcClearanceReport',
                                'fa-duotone fa-circle-check',
                                '/app/main/reports/PdcClearance'
                            ),
                            new AppMenuItem(
                                'StockRequest',
                                'Pages.StockRequestReport',
                                'fa-duotone fa-truck-flatbed',
                                '/app/main/reports/stock-Request'
                            ),
                            new AppMenuItem(
                                'StockTransfer ',
                                'Pages.StockTransferReport',
                                'fa-duotone fa-truck',
                                '/app/main/reports/stock-transfer'
                            ),
                            new AppMenuItem(
                                'StockReceipt',
                                'Pages.StockReceiptReport',
                                'fa-duotone fa-receipt',
                                '/app/main/reports/stock-Receipt'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'PurchaseReport',
                        'PagesPurchaseReport',
                        'fa-duotone fa-receipt',
                        '',
                        [],
                        [
                            // new AppMenuItem("Purchase", "Pages.PurchaseReport", "fa-duotone fa-cart", "/app/main/reports/purchase"),
                            new AppMenuItem(
                                'Purchase Order',
                                'Pages.PurchaseOrderReport',
                                'fa-duotone fa-cart-plus',
                                '/app/main/reports/purchase-order'
                            ),
                            new AppMenuItem(
                                'Material Receipt',
                                'Pages.MaterialReceiptReport',
                                'fa-duotone fa-file-invoice-dollar',
                                '/app/main/reports/material-receipt'
                            ),
                            new AppMenuItem(
                                'Rejection Out',
                                'Pages.RejectionOutReport',
                                'fa-duotone fa-cart-xmark',
                                '/app/main/reports/purchase-rejection'
                            ),
                            new AppMenuItem(
                                'Unpurchased Report',
                                '',
                                'fa-duotone fa-cart-xmark',
                                '/app/main/reports/unpurchase-report'
                            ),
                            new AppMenuItem(
                                'Purchase Invoice',
                                'Pages.PurchaseReport',
                                'fa-duotone fa-cart-flatbed-boxes',
                                '/app/main/reports/purchase-master'
                            ),
                            new AppMenuItem(
                                'Import Purchase',
                                'Pages.PurchaseReturnReport',
                                'fa-duotone fa-file-import',
                                '/app/main/reports/import-purchase'
                            ),
                            new AppMenuItem(
                                'Purchase Return',
                                'Pages.PurchaseReturnReport',
                                'fa-duotone fa-rotate-left',
                                '/app/main/reports/purchase-return'
                            ),
                            new AppMenuItem(
                                'Purchase Party Wise Tax',
                                'Pages.PurchaseReport',
                                'fa-duotone fa-percent',
                                '/app/main/reports/purchase-party-tax'
                            ),

                            new AppMenuItem(
                                'Product Wise Monthly',
                                'Pages.PurchaseReturnReport',
                                'fa-duotone fa-calendar',
                                '/app/main/reports/productWiseMonthlyPurchase'
                            ),
                        ]
                    ),
                    // new AppMenuItem("C-O Report", "Pages.CoReport", "fa-duotone fa-file-earmark-bar-graph", "/app/main/reports/c-o"),
                    // new AppMenuItem("c-O-Salesman", "Pages.CoSalesmanReport", "fa-duotone fa-person-bounding-box", "/app/main/reports/c-o-salesman"),
                    // new AppMenuItem("Damaged Stock", "ages.DamagedStockReport", "fa-duotone fa-basket3", "/app/main/reports/damaged-stock"),
                    new AppMenuItem(
                        'SalesReport',
                        'Pages.SalesReporting',
                        'fa-duotone fa-chart-waterfall',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'SalesDashboard',
                                'Pages.PayHeads',
                                'fa-duotone fa-gauge',
                                '/app/main/reports/sales/salesDashboard'
                            ),
                            new AppMenuItem(
                                'Sales Quotation',
                                'Pages.SalesQuotationReport',
                                'fa-duotone fa-pen-field',
                                '/app/main/reports/sales/salesQuotation'
                            ),
                            new AppMenuItem(
                                'Sales Order',
                                'Pages.SalesOrderReport',
                                'fa-duotone fa-cart-plus',
                                '/app/main/reports/sales/salesorder'
                            ),
                            new AppMenuItem(
                                'Delivery Note',
                                'Pages.DeliveryNoteReport',
                                'fa-duotone fa-memo-pad',
                                '/app/main/reports/sales/deliveryNote'
                            ),
                            new AppMenuItem(
                                'Rejection In',
                                'Pages.RejectionInReport',
                                'fa-duotone fa-circle-xmark',
                                '/app/main/reports/sales/rejectionin'
                            ),
                            new AppMenuItem(
                                'UnSold Report',
                                '',
                                'fa-duotone fa-cart-xmark',
                                '/app/main/reports/unsold-report'
                            ),
                            new AppMenuItem(
                                'Sales VAT Register',
                                'Pages.SalesReport',
                                'fa-duotone fa-file-invoice-dollar',
                                '/app/main/reports/sales/salesMasters'
                            ),
                            new AppMenuItem(
                                'Sales Return',
                                'Pages.SalesReturnReport',
                                'fa-duotone fa-arrow-rotate-left',
                                '/app/main/reports/sales-return'
                            ),
                            // new AppMenuItem("Sales Party Tax", "Pages.Administration.Users", "fa-duotone fa-wallet2", "/app/main/reports/sales-party-tax"),
                            new AppMenuItem(
                                'Material Sales',
                                'Pages.MaterialSalesReport',
                                'fa-duotone fa-chart-waterfall',
                                '/app/main/reports/material-sales'
                            ),
                            new AppMenuItem(
                                'LedgerWiseSales',
                                'Pages.MaterialSalesReport',
                                'fa-duotone fa-chart-gantt',
                                '/app/main/reports/sales/ledgerWiseSales'
                            ),
                            new AppMenuItem(
                                'ProductWiseSales',
                                'Pages.MaterialSalesReport',
                                'fa-duotone fa-chart-line-up',
                                '/app/main/reports/sales/productWiseSales'
                            ),
                            new AppMenuItem(
                                'LedgerWiseMonthly',
                                'Pages.ReceiptVoucherReport',
                                'fa-duotone fa-circle-info',
                                '/app/main/reports/ledger-wise-monthly-report'
                            ),
                            new AppMenuItem(
                                'PartyWise',
                                'Pages.ReceiptVoucherReport',
                                'fa-duotone fa-circle-info',
                                '/app/main/reports/partywise'
                            ),
                            new AppMenuItem(
                                'PartyWiseReport',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/partywisereport'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'TaxReport',
                        'Pages.TaxReporting',
                        'fa-duotone fa-memo-pad',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'VatSummaryReport',
                                'Pages.VatSummaryReport',
                                'fa-duotone fa-percent',
                                '/app/main/reports/tax-report/vatSummaryNewReport'
                            ),
                            new AppMenuItem(
                                'Purchase Tax Report',
                                'Pages.TaxPurchaseRegisterReport',
                                'fa-duotone fa-chart-mixed',
                                '/app/main/reports/tax-report/purchaseTaxReport'
                            ),
                            new AppMenuItem(
                                'Sales Tax Report',
                                'Pages.TaxSalesRegisterReport',
                                'fa-duotone fa-chart-line-up',
                                '/app/main/reports/tax-report/salesTaxReport'
                            ),
                            new AppMenuItem(
                                'Sales Above Lakhs',
                                'Pages.SalesReturnReport',
                                'fa-duotone fa-chart-line',
                                '/app/main/reports/tax-report/salesAboveLakhsReport'
                            ),
                            new AppMenuItem(
                                'Purchase Above Lakhs',
                                'Pages.SalesReturnReport',
                                'fa-duotone fa-cart-shopping',
                                '/app/main/reports/tax-report/purchaseAboveLakhsReport'
                            ),
                            new AppMenuItem(
                                'Party wise tax',
                                'Pages.SalesReturnReport',
                                'fa-duotone fa-badge-percent',
                                '/app/main/reports/partyWiseTax'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Service',
                        'Pages.ServiceReporting',
                        'fa-duotone fa-screwdriver-wrench',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Job Pending',
                                'Pages.ServicePendingMobileReport',
                                'fa-duotone fa-hourglass-clock',
                                '/app/main/reports/service-pending-mobile'
                            ),
                            new AppMenuItem(
                                'Job Detail',
                                'Pages.ServicePendingMobileReport',
                                'fa-duotone fa-memo-circle-info',
                                '/app/main/reports/service-pending'
                            ),
                            new AppMenuItem(
                                'Job Status',
                                'Pages.ServiceDeliveryReport',
                                'fa-duotone fa-shield-check',
                                '/app/main/reports/service-delivery'
                            ),
                            // new AppMenuItem("Service ", "Pages.ServiceReport", "fa-duotone fa-star", "/app/main/reports/service"),
                            // new AppMenuItem(
                            //     "Warranty Cancellation ",
                            //     "Pages.WarrantyCancellationReport",
                            //     "fa-duotone fa-list-task",
                            //     "/app/main/reports/warranty-cancellation"
                            // ),
                        ]
                    ),
                    new AppMenuItem(
                        'Ticket Report',
                        'Pages.Service',
                        'fa-duotone fa-chart-simple',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Ticket Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/ticketReport'
                            ),
                            new AppMenuItem(
                                'Employee Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/employeeTicket'
                            ),
                            new AppMenuItem(
                                'OutGoingSpare Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/outgoingsparereport'
                            ),
                            new AppMenuItem(
                                'IncomingSpare Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/incomingsparereport'
                            ),
                            new AppMenuItem(
                                'Spare Stock Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/sparestockreport'
                            ),
                            new AppMenuItem(
                                'Product Stock Report',
                                'Pages.PartyWiseReport',
                                'fa-duotone fa-notes',
                                '/app/main/reports/productstockreport'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Confirmation Report',
                        'Pages.PurchaseReturnReport',
                        'fa-duotone fa-calendar-days',
                        '/app/main/reports/confirmationLetter'
                    ),
                ]
            ),
            new AppMenuItem(
                'Setting',
                '',
                'fa-duotone fa-gear',
                '',
                [],
                [
                    new AppMenuItem('Company', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
                    new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
                    new AppMenuItem('LeadSource', 'Pages.LeadSources', 'fa-duotone fa-buildings', '/app/main/controlPanel/leadsource'),
                    new AppMenuItem('TaskCategory', 'Pages.TaskCategory', 'fa-duotone fa-buildings', '/app/main/crm/taskcategory'),
                    new AppMenuItem('LeadType', 'Pages.LeadType', 'fa-duotone fa-buildings', '/app/main/crm/leadtype'),
                    new AppMenuItem(
                        'Notifications',
                        '',
                        'fa-duotone fa-alarm-clock',
                        '',
                        [],
                        [
                            new AppMenuItem('Inbox', '', 'fa-duotone fa-envelope', '/app/notifications'),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Administration.MassNotification',
                                'fa-duotone fa-paper-plane',
                                '/app/admin/mass-notifications'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'fa-duotone fa-house-blank',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'fa-duotone fa-language',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'fa-duotone fa-list-check',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'fa-duotone fa-sliders',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'fa-duotone fa-hand-pointer',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'fa-duotone fa-eye',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'fa-duotone fa-bell',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'fa-duotone fa-star',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'fa-duotone fa-gear',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'fa-duotone fa-gear',
                        '/app/admin/tenantSettings'
                    ),
                ]
            ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
